<template>
    <b-modal id="modal-config-termos" hide-footer size="lg">
        <div class="spaceModal">
            <div class="header">
                <div class="title">Configurar Termos</div>
                <div class="subtitle">Possível adicionar um vídeo de introdução e um texto com os termos</div>
            </div>
            <div class="lineDiv"></div>
            <div>
                <b-form-group class="upload-area" label="Vídeo de Introdução (Opcional)" label-for="descricao">
                    <div
                        class="btn-openModal"
                        @click="showModalUpload"
                        v-if="midiaTermosUrl === null"
                    >
                        <p class="text">Clique e faça o Upload do Vídeo</p>
                    </div>
                    <div
                        class="btn-openModalPreview"
                        @click="showModalUpload"
                        v-else
                    >
                        <iframe v-if="mediaTypeLesson === 'panda'" class="imagePreviewYt animationOpacity" height="100%" width="50%" :src="midiaTermosUrl+'&controls=play-larg'" style="border:0;pointer-events: all;"></iframe>
                        <img
                            v-else
                            class="imagePreviewThumb animationOpacity"
                            :src="midiaTermos"
                            :alt="midiaTermos"
                        />
                    </div>
                    <div class="removeLessonVideo" @click="removeCourse()" v-if="midiaTermosUrl !== null">
                        <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24" 
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M18 6L6 18"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M6 6L18 18"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        </svg>
                    </div>
                </b-form-group>
                <b-form-group label="Termos" label-for="descricao">
                    <quill-editor
                    ref="myQuillEditor"
                    v-model="termosPainel"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    />
                </b-form-group>
            </div>
            <div class="footer">
                <button @click="salvarDados">Salvar</button>
            </div>
        </div>
    </b-modal>    
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ModalUpload from "@/components/ModalUpload.vue";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

export default {
    components:{
    quillEditor,
    ModalUpload
  },
  data(){
    return{
        termosPainel:'texto',
        editorOption: {
          // Some Quill options...
        },
        midiaTermos:null,
        midiaTermosUrl:null,
        mediaTypeLesson:''
    }
  },
  methods:{
    async removeCourse(){
        this.$root.$emit('loadOn');

        const metaIntroductoryVideo = JSON.stringify({ "url": null, "thumb": null, "type": null });

        var data = {
            id: `introductoryVideo`,
            value: metaIntroductoryVideo,
        }

        await serviceMeta
        .postID(data)
        .then(async() => {
            this.midiaTermosUrl = null;
            this.midiaTermos = null;
            this.mediaTypeLesson = "";
            this.$store.commit("UPDATE_META", { key: "introductoryVideo", value: metaIntroductoryVideo });
            this.$bvToast.toast(`Video removido com sucesso!`, {
                title: "Termos",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });
        }).catch((err) => {
            this.$bvToast.toast(`Erro ao remover video!`, {
                title: "Termos",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
            });
            console.error(err);
        }).finally(() => {
            this.$root.$emit('loadOff');
        })
    },
    async salvarDados(){
        this.$root.$emit('loadOn');

        const metaIntroductoryVideo = JSON.stringify({"url":this.midiaTermosUrl, "thumb":this.midiaTermos, "type":this.mediaTypeLesson});

        var data = {
            id: `introductoryVideo`,
            value: metaIntroductoryVideo,
        }

        await serviceMeta
        .postID(data)
        .finally(() => {
            this.$store.commit("UPDATE_META", { key: "introductoryVideo", value: metaIntroductoryVideo });
        })
        .catch((err) => {
            console.error(err);
        }).finally(() => {
            this.$root.$emit('loadOff');
        })

        var data1 = {
            id: `termsOfUse`,
            value: this.termosPainel,
        }
        this.$root.$emit('loadOn');

        await serviceMeta
        .postID(data1)
        .then(async(resp) => {
            this.$store.commit("UPDATE_META", { key: "termsOfUse", value: resp.value });
            this.termosPainel = resp.value

            this.$bvToast.toast(`Termos atualizado com sucesso!`, {
                title: "Termos",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });

            this.$root.$emit('loadOff');
            this.$root.$emit('bv::hide::modal', 'modal-config-termos', '#btnShow')
        }).catch((err) => {
            this.$bvToast.toast(`Erro ao atualizar termos!`, {
                title: "Termos",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
            });
            console.error(err);
            this.$root.$emit('loadOff');
        });
    },
    async getMetaTermosVideo(){
        this.$root.$emit('loadOn');
        let resp = await this.$store.dispatch("fetchMetas", [
            "introductoryVideo"
        ])
        var data = JSON.parse(resp.introductoryVideo)
        this.midiaTermos = data.thumb;
        this.midiaTermosUrl = data.url;
        this.$root.$emit('loadOff');
    },
    async getMetaTermosTxt(){
        this.$root.$emit('loadOn');
        let resp = await this.$store.dispatch("fetchMetas", [
            "termsOfUse"
        ])
        this.termosPainel = resp.termsOfUse;
        this.$root.$emit('loadOff');
    },
    showModalUpload(){
      this.$root.$emit("videotermos");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    onEditorBlur(quill) {
      //console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      //console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      //console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      //console.log("editor change!", quill, html, text);
      this.content = html;
    },
  },
  async mounted(){
    await this.getMetaTermosTxt();

    this.$root.$on('bv::modal::show', async (bvEvent, modalId) => {
      if(modalId === 'modal-config-termos')
        await this.getMetaTermosTxt();
        await this.getMetaTermosVideo();
    })
    this.$root.$on("midiatermoyt", (data) => {
        //console.log("data video", data);
        this.mediaTypeLesson = "youtube"
        this.midiaTermos = data.snippet.thumbnails.standard.url;
        this.midiaTermosUrl = 'https://www.youtube.com/watch?v='+data.id.videoId
    });
    this.$root.$on("midiatermopanda", (data) => {
        //console.log("data video", data);
        this.mediaTypeLesson = "panda"
        this.midiaTermos = data.cdn_url;
        this.midiaTermosUrl = data.cdn_url
    });
    this.$root.$on("midiatermovimeo", (data) => {
        //console.log("data video vimeo", data);
        this.mediaTypeLesson = "vimeo"
        this.midiaTermos = data.pictures.sizes[0].link;
        this.midiaTermosUrl = data.link;
    });
    this.$root.$on("midiatermoiframe", (data) => {
        //console.log("data video iframe", data);
        this.mediaTypeLesson = "iframe"
        this.midiaTermos = 'https://app.greenn.club/iframe.png';
        this.midiaTermosUrl = data.link;
    });
  }
}
</script>
<style lang="scss" scoped>
.upload-area{
    position: relative;

  .removeLessonVideo {
    position: absolute;
    top: 40px;
    right: 20px;
    opacity: 1;
    transition: 0.3s;

    svg {
      filter: var(--filtericon);
    }
  }
}

.spaceModal{
    padding: 40px;
    margin-top: -40px;
}

.footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button{
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        height: 45px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.2px;
        color: #FFFFFF;
        width: 251.29px;
    }
}

.btn-openModal{
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    cursor: pointer;
    .text{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor) !important;
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }
}

.btn-openModalPreview{
      background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    img{
        border-radius: 3px;
    }
    .imagePreviewThumb{
        height: 150px;
    }
}

.lineDiv{
    width: 100%;
    height: 0.5px;
    background: #E5E5E5;
    transform: matrix(1, 0, 0, -1, 0, 0);
    margin-top: 30px;
    margin-bottom: 30px;
}
.header{
    .title{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: #333333;
        margin-bottom: 5px;
    }
    .subtitle{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
    }
}
</style>
