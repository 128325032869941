import MetaService from '@/services/resources/MetaService';

const serviceMeta = MetaService.build();

const state = {
  metas: {},
  fetchingMetas: {},
};

const mutations = {
  SET_META(state, { key, value }) {
    state.metas[key] = value;
  },
  RESET_METAS(state) {
    state.metas = {};
    state.fetchingMetas = {};
  },
  REMOVE_META(state, key) {
    delete state.metas[key];
  },  
  SET_FETCHING(state, { key, promise }) {
    state.fetchingMetas[key] = promise;
  },
  REMOVE_FETCHING(state, key) {
    delete state.fetchingMetas[key];
  },
  UPDATE_META(state, { key, value }) {
    state.metas[key] = value;
  },
};

const actions = {
  async fetchMetas({ state, commit }, keysToSearch) {
    const missingKeys = [];
    const promisesToWait = [];

    for (let key of keysToSearch) {
      if (!state.metas.hasOwnProperty(key)) {
        if (state.fetchingMetas[key]) {
          // Se já estamos buscando esta meta, aguarde a promessa existente
          promisesToWait.push(state.fetchingMetas[key]);
        } else {
          // Iniciar uma nova busca para esta meta
          missingKeys.push(key);
        }
      }
    }

    if (missingKeys.length > 0) {
      let queryString = missingKeys.map(key => `keys[]=${key}`).join('&');

      const promise = serviceMeta.search(queryString).then(response => {
        for (let key in response) {
          if (response.hasOwnProperty(key)) {
            commit('SET_META', { key: key, value: response[key] });
            commit('REMOVE_FETCHING', key);
          }
        }
      });

      missingKeys.forEach(key => {
        commit('SET_FETCHING', { key: key, promise });
      });

      promisesToWait.push(promise);
    }
    await Promise.all(promisesToWait);
    const result = {};
    for (let key of keysToSearch) {
      result[key] = state.metas[key];
    }
    return result;
  },
  async updateMetas ({commit}, key){
    await commit("SET_META", {key: key.id, value: key.value});
  },
};

const getters = {
  getMetas: (state) => state.metas,
  getMeta: (state) => (key) => {
    return state.metas[key] || null;
  },
};


export {
  state,
  mutations,
  actions,
  getters
};